import "./Footer.scss";
import { ReactComponent as Deco } from "../../img/longdecor.svg";

const FooterSection = () => {
  return (
    <section className="Footer" id="contact">
      <div className="Footer__content">
        <div className="Footer__left">
          <p className="address">Taastrup Hovedgade 77, 3, 2630 Taastrup</p>
          <a href="mailto:sushiamortaastrup@gmail.com">
            sushiamortaastrup@gmail.com
          </a>
          <p className="mobile">
            <span>{`\xa0|\xa0`}</span>
            <a href="tel:+4532211222">+45 32 21 12 22</a>
          </p>
          <p className="opening-hours">åbningstider</p>
          <p>Mandag - Lørdag: 14:30 - 21:00</p>
          <p>Søndag: Lukket</p>
          <p className="special">
            (køkken lukket 15 minutter før restauranten lukker)
          </p>
          <p className="findSmiley special">
            Tjek kontrolrapport vedr
            <a
              rel="noreferrer"
              className="findSmiley"
              href="https://www.findsmiley.dk/933225"
              target={"_blank"}
            >
              {"\xa0"}findSmiley.dk
            </a>
          </p>
          <Deco className="Footer__deco" />
        </div>
        <div className="Footer__right">
          <a
            rel="noreferrer"
            target={"_blank"}
            href="https://facebook.com/sushiamortaastrup"
            className="social-link"
            title="Sushi Amor Taastrup Facebook page "
          >
            Facebook
          </a>
        </div>
      </div>
    </section>
  );
};

export default FooterSection;
