import "./NotFoundPage.scss";

const NotFoundPage = () => {
  return (
    <section className="not-found">
      <p>Page not found !</p>
    </section>
  );
};

export default NotFoundPage;
