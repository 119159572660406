import "./index.scss";
import { ReactComponent as CloseBtn } from "./../../../img/close-btn.svg";
import { useState } from "react";
export const NewYearMenu = () => {
  const date = new Date("2023-12-31").valueOf();
  const now = new Date().valueOf();
  const [showNotification, setShowNotification] = useState(true);
  const [showPopUp, setShowPopup] = useState(false);

  if (now > date) {
    return null;
  }

  const hideNotification = () => {
    setShowNotification(false);
  };

  return (
    <div
      className="nt-container"
      style={{ bottom: showNotification ? 0 : "150vh" }}
    >
      <div
        className="menu-page"
        style={{
          transform: `transform: translate(-50%, -${50}%)`,
        }}
      >
        <div className="information">
          <h1 className="notice">Notifikation</h1>
          <p className="close-time">Den 24/12-26/12 lukker vi .</p>
          <p className="close-time">Den 31/12 åbner vi kun for takeaway.</p>
          <p className="menu-dcr">
            Vi har lavet 2 nye nytårsmenuer til dig og din familie. Alle
            medarbejdere ønsker glædelig jul og godt nytår.
          </p>
          <button className="see-menu" onClick={() => setShowPopup(true)}>
            se nytårsmenu
          </button>
          <p className="how-to">
            du kan bestille nu fra vores{" "}
            <a
              href="https://sushiamor.dk/Taastrup/onlineBestiling.html"
              className="order-link"
            >
              menu side
            </a>{" "}
            eller bare giv os et kald på{" "}
            <a href="tel:+4532211222" className="order-link">
              +45 32 21 12 22
            </a>
          </p>
          <button className="close-button-square" onClick={hideNotification}>
            close
          </button>
          <button className="close-button-circle" onClick={hideNotification}>
            <CloseBtn />
          </button>
          <div
            className="menu-img-popup"
            style={{ left: showPopUp ? "50%" : "150%" }}
          >
            <button className="close-popup" onClick={() => setShowPopup(false)}>
              <CloseBtn />
            </button>
          </div>
        </div>
        <div className="menu-img" />
      </div>
    </div>
  );
};
