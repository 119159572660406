import cover_img from "../../../../../img/sashmi.png";
import { ReactComponent as Decor } from "../../../../../img/decor.svg";
import "./Slide.scss";
const AlibitumSlide = () => {
  return (
    <div className="Slide">
      <div
        style={{
          backgroundImage: `url(${cover_img})`,
        }}
        className="Slide__image"
      />
      <div className="Slide__TextLayer TextLayer">
        <div className="TextLayer__dector">
          <Decor />
        </div>
        <p className="TextLayer__subtitle">eat as much as you can</p>
        <h1 className="TextLayer__title">Sushi Ad libitum</h1>
        <div className="TextLayer__text-block ">
          <p className="TextLayer__text-block">
            Ved du ikke hvad du skal spise? Vores Ad libitum-menu
            giver dig mulighed for at vælge hvad du har lyst til, og så meget som du
            har lyst til. Den eneste regel er, at du ikke må have mere end 3 stykker
            tilbage på dit bord, når du afslutter din middag.
          </p>
        </div>
      </div>
    </div>
  );
};
export default AlibitumSlide;
