import "./App.scss";
import Header from "./Header/";
import BodyDecorationLine from "./BodyDecorationLine";
import Footer from "./Footer";
import Home from "./Pages/Home";
import { Routes, Route } from "react-router-dom";
import NotFoundPage from "./Pages/NotFoundPage";
import { NewYearMenu } from "./Pages/NewYearMenu";
// import { UserSection } from "./user/UserSection";
function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/user/*" element={<UserSection />} /> */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
      <BodyDecorationLine />
      <NewYearMenu />
    </div>
  );
}

export default App;
