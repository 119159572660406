import "./LatestItem.scss";
export const LatestItem = ({ img, imgName, subtitle, text }) => {
  return (
    <div className="latestItem">
      <img className="latestItem__itemImg" src={img} alt={imgName} />
      <img className="latestItem__itemImg" src={img} alt={imgName} />
      <img className="latestItem__itemImg" src={img} alt={imgName} />
      <span className="latestItem__subtitle">{subtitle}</span>
      <p>{text}</p>
    </div>
  );
};
