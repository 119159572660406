import { Fragment } from "react";
import "./BodyDecorationLine.scss";
const BodyDecorationLine = () => {
  return (
    <Fragment>
      <div className="BodyDecorationLine BodyDecorationLine--left"></div>
      <div className="BodyDecorationLine BodyDecorationLine--right"></div>
    </Fragment>
  );
};
export default BodyDecorationLine;
