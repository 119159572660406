import { Fragment } from "react";
import AboutSection from "./AboutSection";
import AdLibitumSection from "./AdLibitumSection";
import SliderSection from "./SliderSection";
import LatestSection from "./LatestSection";

const Home = () => {
  return (
    <Fragment>
      <SliderSection />
      <AdLibitumSection />
      <LatestSection />
      <AboutSection />
    </Fragment>
  );
};

export default Home;
