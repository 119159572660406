import cover_img from "../../../../../img/gukan.png";
import { ReactComponent as Decor } from "../../../../../img/decor.svg";
import "./Slide.scss";
const FreshIngredientsSlide = () => {
  return (
    <div className="Slide">
      <div
        style={{
          backgroundImage: `url(${cover_img})`,
        }}
        className="Slide__image"
      />
      <div className="Slide__TextLayer TextLayer">
        <div className="TextLayer__dector">
          <Decor />
        </div>
        <p className="TextLayer__subtitle">Friske og smagfulde ingredienser</p>
        <h1 className="TextLayer__title">Lækker sushi</h1>
        <div className="TextLayer__text-block ">
          <p className="TextLayer__text-block">
            Vi vælger altid den friskeste fisk blandt alle vores udbydere i
            Danmark. De fleste af vores retter og saucer er hjemmelavede frem for
             at købe dem, der er præproduceret af fabrikker, så vi kan sikre
            os vores mad har en unik smag.
          </p>
        </div>
      </div>
    </div>
  );
};
export default FreshIngredientsSlide;
