import { ReactComponent as LogoImg } from "../../img/sushiamor-gold.svg";
import "./Logo.scss";
const Logo = () => {
  return (
    <div className="Logo">
      <LogoImg />
      <h1 className="Logo__title">Sushi Amor</h1>
    </div>
  );
};
export default Logo;
