import Slider from "./Slider";
import "./SliderSection.scss";
import AlibitumSlide from "./slides/AlibutumSlide";
import VarietySelectionSlide from "./slides/VarietySelectionSlide";
import FreshIngredientsSlide from "./slides/FreshIngredientsSlide";
const SliderSection = () => {
  const slides = [AlibitumSlide, VarietySelectionSlide, FreshIngredientsSlide];
  return (
    <section className="SliderSection">
      <Slider slides={slides} />
    </section>
  );
};

export default SliderSection;
