import { ReactComponent as TitleLeft } from "../../img/titleleft.svg";
import { ReactComponent as TitleRight } from "../../img/titleright.svg";
import "./title.scss";
const Title: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div className="title">
      <TitleLeft />
      <h1>{text}</h1>
      <TitleRight />
    </div>
  );
};

export default Title;
