import cover_img from "../../../../../img/cripsyreje.png";
import { ReactComponent as Decor } from "../../../../../img/decor.svg";
import "./Slide.scss";
const VarietySelectionSlide = () => {
  return (
    <div className="Slide">
      <div
        style={{
          backgroundImage: `url(${cover_img})`,
        }}
        className="Slide__image"
      />
      <div className="Slide__TextLayer TextLayer">
        <div className="TextLayer__dector">
          <Decor />
        </div>
        <p className="TextLayer__subtitle">
          Sushi, tempura, sticks, en blanding af alt
        </p>
        <h1 className="TextLayer__title">En række udvalg</h1>
        <div className="TextLayer__text-block ">
          <p className="TextLayer__text-block">
            Vi har ikke kun sushi, vi serverer også salat, tempura, pinde, rispapir,
            sashimi, nigiri, gunkan og mere. Tjek vores menu for at se dem alle.
          </p>
        </div>
      </div>
    </div>
  );
};
export default VarietySelectionSlide;
