import Title from "../../../shared/title";
import SubTitle from "../../../shared/SubTitle";
import "./AdLibitumSection.scss";
const AdLibitumSection = () => {
  return (
    <section className="adlibitum" id="libitum">
      <Title text="Ad libitum" />
      <SubTitle text={`Sushi Amor tilbyder sushi ad libitum.`} />
      <div className="adlibitum__content">
        <div className="adlibitum_introduction">
          <p>
            Sushi ad Libitum er et koncept, hvor du kan spise dig mæt i din
            yndlings sushi.
          </p>
          <p>
            Du kan bestille alt det du har lyst til, præcis det du har lyst til
            og sushien bliver serveret absolut frisklavet.
          </p>
          <p>
            <span className="price">Ad Libitum Pris</span>
          </p>
          <p>
            Mandag - Torsdag: <span className="price">228kr/person</span>
          </p>
          <p>
            Fredag - Lørdag: <span className="price">248kr/person</span>
          </p>
          <p>
            <span className="price cancelled">Ad Libitum frokost</span>
            <span className="price "> </span>
            <span className="price " style={{ color: "var(--color-primary)" }}>
              {" "}
              midlertidigt aflyst
            </span>
          </p>
          <p className="cancelled">
            Alle dage før kl 15.00:
            <span className="price"> 188kr/person</span>{" "}
          </p>
          <p>Børn under 10 år (inklusive) er halv pris.</p>
        </div>
        <div className="adlibitum__image" />
      </div>
    </section>
  );
};
export default AdLibitumSection;
