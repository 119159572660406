import { ReactComponent as LeftArrow } from "../../../../../img/left.svg";
import { ReactComponent as RightArrow } from "../../../../../img/right.svg";
import { ReactComponent as Indicator } from "../../../../../img/ol.svg";
import { ReactComponent as IndicatorActive } from "../../../../../img/olactive.svg";
import "./Slider.scss";
import { FC } from "react";
import { useState } from "react";
import { BOOKING_TABLE_URL, ONLINE_ORDER_URL } from "../../../../../config";
const Slider: FC<{ slides: FC[] }> = ({ slides }) => {
  const [index, setIndex] = useState(0);
  const CurrentSlide = slides[index];
  return (
    <div className="Slider">
      <CurrentSlide />
      <div
        className="Slider__left-control"
        onClick={() => {
          setIndex((index - 1 + slides.length) % slides.length);
        }}
      >
        <LeftArrow />
      </div>
      <div
        className="Slider__right-control"
        onClick={() => {
          setIndex((index + 1 + slides.length) % slides.length);
        }}
      >
        <RightArrow />
      </div>
      <div className="Slider__fixed-links">
        <a href={ONLINE_ORDER_URL}>online bestilling</a>
        <a href={BOOKING_TABLE_URL}>reservere bord</a>
      </div>
      <div className="Slider__number-indicator">
        {slides.map((omit, number) =>
          index === number ? (
            <IndicatorActive key={number} onClick={() => setIndex(number)} />
          ) : (
            <Indicator key={number} onClick={() => setIndex(number)} />
          )
        )}
      </div>
    </div>
  );
};
export default Slider;
