import Title from "../../../shared/title";
import SubTitle from "../../../shared/SubTitle";
import "./About.scss";
const AboutSection = () => {
  return (
    <section className="about" id="about">
      <Title text="OM OS" />
      <SubTitle
        text={`Sushi Amor er et navn, der stammer fra det portugisiske, hvilket betyder "kærlighed".`}
      />
      <div className="about__content">
        <div className="about__image" title="Sushi Amor Taastrup" />
        <div className="about_introduction">
          <p>
            I begyndelsen af 90'erne arbejdede den første ejer af denne
            restaurant som en køkkenhjælper i en japansk restaurant i Portugal.
          </p>
          <p>
            Efter mange års arbejde og læring i den sushi-restaurant, han blev
            sushi kok. I 2018 startede han sin egen Restaurant i Danmark, som
            hedder Sushi Amor.{" "}
          </p>
          <p>
            Han mente, at Sushi burde være overkommelig for almindelige familier. Med
            dette i tankerne, designede han menuen, der ikke er blevet ændret
            siden i alle Sushi Amor restauranter.
          </p>
        </div>
      </div>
    </section>
  );
};
export default AboutSection;
