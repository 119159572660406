import "./Header.scss";
import "./DropdownMenu.scss";
import Logo from "./Logo";
import LuckySquare from "./LuckySquare";
import { ReactComponent as Separator } from "../../img/olactive.svg";
import { CSSProperties, useState } from "react";
import { ReactComponent as CloseBtn } from "../../img/close-btn.svg";
import { NavLink } from "react-router-dom";
import { BOOKING_TABLE_URL, ONLINE_ORDER_URL } from "../../config";
const Header = () => {
  const [showDropdown, toggleDropdown] = useState(false);

  const checkActiveStyle: (props: { isActive: boolean }) => CSSProperties = ({
    isActive,
  }) => {
    return isActive
      ? {
          color: "var(--color-primary)",
        }
      : {};
  };

  return (
    <div className="Header">
      <LuckySquare style={{ left: 0 }} />
      <div className="Navbar">
        <ul>
          <li>
            <NavLink style={checkActiveStyle} to={"/"}>
              FORSIDE
            </NavLink>
          </li>
          <Separator />
          <li>
            <a href={ONLINE_ORDER_URL}>MENU</a>
          </li>
          <Separator />

          <li>
            <a href={BOOKING_TABLE_URL}>BOOKINGBORD</a>
          </li>
        </ul>
        <Logo />
        <ul>
          <li>
            <a href="/#contact">KONTAKT</a>
          </li>
          <Separator />
          <li>
            <a href="/#about">OM OS</a>
          </li>
          <Separator />
          <li>
            <a href="/#libitum">AD LIBITUM</a>
          </li>
          <Separator />
          <li>
            <a href="/#latest">Seneste</a>
          </li>
        </ul>
        <p
          className="Navbar__open-button"
          onClick={() => {
            toggleDropdown(!showDropdown);
          }}
        >
          åben menu
        </p>
      </div>
      <ul
        className={`DropdownMenu ${
          showDropdown ? "DropdownMenu--visible" : "DropdownMenu--invisible"
        }`}
      >
        <div
          className="DropdownMenu__closeBtn"
          onClick={() => {
            toggleDropdown(!showDropdown);
          }}
        >
          <CloseBtn />
        </div>
        <li>
          <a href={ONLINE_ORDER_URL}> online bestilling</a>
        </li>
        <li>
          <a href={BOOKING_TABLE_URL}>reservere bord</a>
        </li>
        <li>
          <a href="tel:+4532211222"> Ring til os: +45 32 21 12 22</a>
        </li>
      </ul>
      <LuckySquare style={{ right: 0 }} />
    </div>
  );
};

export default Header;
