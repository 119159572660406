import { LatestItem } from "../../../LatestItem";
import Title from "../../../shared/title";
import "./LatestSection.scss";

const LatestSection = () => {
  return (
    <section className="latest" id="latest">
      <Title text="Senest Ændring" />
      <div className="latest__latest-container">
        <LatestItem
          img={require("../../../../img/ice-cream.png")}
          subtitle={"Dessert tilføjes til Ad libitum-menuen."}
          text={`Vi har tilføjet is med vaniljesmag og chokoladesmag i vores
           Ad libitum menu, som forhåbentlig giver dig en sød madoplevelse.`}
        />
        <LatestItem
          img={require("../../../../img/cake.png")}
          imgName={"cake image"}
          subtitle={"Tillykke med fødselsdagen!"}
          text={`Vil du fejre din fødselsdag i vores restaurant? 
          Vælg en flaske øl, vin, sake eller andet til en max værdi på 20% af 
          din regning. Vi ønsker dig en god tid i vores restaurant.
          (Vi forbeholder os retten til at kontrollere dit id.)
          `}
        />
        <LatestItem
          img={require("../../../../img/coffee-cup.png")}
          imgName={"coffee image"}
          subtitle={"Du vil drikke kaffe, hjælper Nespresso"}
          text={`Vi havde kaffe før, men vi stoppede, fordi vi ikke vidste,
           hvordan man laver god kaffe. Nu løste Nespresso problemet. 
           Vi har 3 slags kapselkaffe og 2 slags iskaffe, kom og tjek det ud.`}
        />
        <LatestItem
          img={require("../../../../img/add.png")}
          imgName={"add more image"}
          subtitle={"Har du flere ideer?"}
          text={`Hvis du har flere ideer eller forslag, så tal med os eller
          bare send os en mail til sushiamortaastrup@gmail.com`}
        />
      </div>
    </section>
  );
};
export default LatestSection;
