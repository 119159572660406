import { ReactComponent as LuckySquareSvg } from "../../img/luckySquare.svg";
import "./LuckySquare.scss";
const LuckySquare = ({ style }) => {
  return (
    <div className="LuckySquare " style={{ ...style }}>
      <LuckySquareSvg />
    </div>
  );
};

export default LuckySquare;
